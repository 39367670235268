<template>
  <div class="copyright d-flex align-center">
    <v-btn href="https://makzani.com" small text tile class="pt-0 pb-0">
      <span class="primary--text" style="font-size: 8pt;">
        MAKZANI Copyright &copy; 2021 | v{{ getVersion }}
      </span>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "Copyright",
};
</script>

<style scoped>
.copyright * {
  cursor: pointer;
}
.copyright pre {
  cursor: default;
}
</style>
