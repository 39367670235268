import { render, staticRenderFns } from "./Copyright.vue?vue&type=template&id=0e26f520&scoped=true&"
import script from "./Copyright.vue?vue&type=script&lang=js&"
export * from "./Copyright.vue?vue&type=script&lang=js&"
import style0 from "./Copyright.vue?vue&type=style&index=0&id=0e26f520&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e26f520",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
